import React, { useEffect, useState } from "react";
import "./css/FormRoles.css";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import $ from "jquery";
import {
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_newRoles,
  url_updateRoles,
  url_getModulePageListAll,
} from "../../utility/Config";
import { getFetch } from "../../utility/CallApi";
import { AlertCommit } from "../../utility/AlertBar";
import isObject from "lodash/isObject";
import ErrorFocus from "./ErrorFocus";

export default function FormRole(props) {
  const [objEdit, setObjEdit] = useState(props.objEditRoles);
  const [modulePageList, setModulePageList] = useState([]);

  // const [is_insert, setIs_insert] = useState('N');
  // const [is_update, setIs_update] = useState('N');
  // const [is_delete, setIs_delete] = useState('N');
  // const [is_print, setIs_print] = useState('N');
  // const [is_import, setIs_import] = useState('N');
  // const [is_export, setIs_export] = useState('N');
  // const [is_approve, setIs_approve] = useState('N');
  // const [is_cancel, setIs_cancel] = useState('N');

  useEffect(async () => {
    console.log(props);
    await setObjEdit(props.objEditRoles);
    await getModulePageListAll();

    return () => {
      setObjEdit(null);
    };
  }, [props.objEditRoles]);

  const getModulePageListAll = async () => {
    let body = {};
    let data = await getFetch(
      url_getModulePageListAll,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      setModulePageList(data.RESULT_DATA);
    } else if (data && data.RESULT_STATUS === "999") {
      return;
    }
  };

  const onSaveRoles = async (e) => {
    console.log("props:::", props);

    let newPageList = "";
    e.pageList.map((p, key) => {
      if (key === 0) {
        newPageList = p;
      } else {
        newPageList = newPageList + "," + p;
      }
    });
    let body = {
      role_code: e.rolesCode,
      role_name: e.rolesName,
      role_desc: e.rolesName,
      is_active: e.selectStatus,
      page_list: newPageList,
      is_insert: e.is_insert === true ? "Y" : "N",
      is_update: e.is_update === true ? "Y" : "N",
      is_delete: e.is_delete === true ? "Y" : "N",
      is_print: e.is_print === true ? "Y" : "N",
      is_import: e.is_import === true ? "Y" : "N",
      is_export: e.is_export === true ? "Y" : "N",
      is_approve: e.is_approve === true ? "Y" : "N",
      is_cancel: e.is_cancel === true ? "Y" : "N",
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_newRoles,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onUpdateRoles = async (e) => {
    console.log("onUpdateRoles:::", props);

    let newPageList = "";
    e.pageList.map((p, key) => {
      if (key === 0) {
        newPageList = p;
      } else {
        newPageList = newPageList + "," + p;
      }
    });
    let body = {
      role_code: e.rolesCode,
      role_name: e.rolesName,
      role_desc: e.rolesName,
      is_active: e.selectStatus,
      page_list: newPageList,
      is_insert: e.is_insert === true ? "Y" : "N",
      is_update: e.is_update === true ? "Y" : "N",
      is_delete: e.is_delete === true ? "Y" : "N",
      is_print: e.is_print === true ? "Y" : "N",
      is_import: e.is_import === true ? "Y" : "N",
      is_export: e.is_export === true ? "Y" : "N",
      is_approve: e.is_approve === true ? "Y" : "N",
      is_cancel: e.is_cancel === true ? "Y" : "N",
      role_id: props.objEditRoles.role_id,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_updateRoles,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onClosePopup = async () => {
    await props.onClosePopup();
  };
  /////////////////////////////////////////////////////
  // const Schema = Yup.object().shape({
  //   rolesCode: Yup.string().required("โปรดใส่ข้อมูล"),
  //   rolesName: Yup.string().required("โปรดใส่ข้อมูล"),
  //   rolesDesc: Yup.string().required("โปรดใส่ข้อมูล"),
  //   acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
  //   // rolesIcon: Yup.string().required("โปรดใส่ข้อมูล"),
  //   rolesSequence: Yup.number().required("โปรดใส่ข้อมูล"),
  //   selectRolesModule: Yup.number().required("โปรดใส่ข้อมูล"),
  //   selectStatus: Yup.string().required("โปรดใส่ข้อมูล"),
  //   rolesPath: Yup.string().required("โปรดใส่ข้อมูล"),
  // });

  // const formik = useFormik({
  //   validationSchema: Schema,
  //   initialValues: {
  //     rolesCode: objEdit ? objEdit.roles_code : "",
  //     rolesName: objEdit ? objEdit.roles_name : "",
  //     rolesDesc: objEdit ? objEdit.roles_desc : "",
  //     acceptTerms: false,
  //     // rolesIcon: objEdit ? objEdit.roles_icon : "",
  //     rolesSequence: objEdit ? objEdit.roles_seq : "",
  //     selectRolesModule: objEdit ? objEdit.module_id : "1",
  //     selectStatus: objEdit ? objEdit.is_active : "Y",
  //     rolesPath: objEdit ? objEdit.roles_path : "",
  //     // pageList: objEdit ? objEdit.page_list : [],

  //   },
  //   onSubmit: async (values) => {
  //     console.log("values:::", values);
  //     // if (props.openPopupUpdateForm) {
  //     //   console.log("update");
  //     //   await onUpdateRoles(values);
  //     // } else {
  //     //   console.log("save");

  //     //   await onSaveRoles(values);
  //     // }
  //   },
  // });

  // const getFirstErrorKey = (object, keys = []) => {
  //   const firstErrorKey = Object.keys(object)[0];
  //   if (isObject(object[firstErrorKey])) {
  //     return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  //   }
  //   return [...keys, firstErrorKey].join(".");
  // };

  // useEffect(() => {
  //   console.log(formik);

  //   if (!formik.isValid && formik.submitCount !== 0 && formik.isSubmitting) {
  //     const firstErrorKey = getFirstErrorKey(formik.errors);
  //     if (global.window.document.getElementsByName(firstErrorKey).length) {
  //       global.window.document.getElementsByName(firstErrorKey)[0].focus();
  //     }
  //   }
  // }, [formik.submitCount, formik.isValid, formik.errors, formik.isSubmitting]);

  // const MyCheckbox = ({ field, form, label, ...rest }) => {
  //   const { name, value: formikValue } = field;
  //   const { setFieldValue } = form;

  //   const handleChange = event => {
  //     const values = formikValue || [];
  //     console.log(values);
  //     const index = values.indexOf(rest.value);
  //     if (index === -1) {
  //       values.push(rest.value);
  //     } else {
  //       values.splice(index, 1);
  //     }
  //     setFieldValue(name, values);
  //   };

  //   return (
  //     <label>
  //       <input
  //         type="checkbox"
  //         onChange={handleChange}
  //         checked={formikValue.indexOf(rest.value) !== -1}
  //         {...rest}
  //       />
  //       <span>{label}</span>
  //     </label>
  //   );
  // };
  return (
    <Formik
      initialValues={{
        rolesCode: objEdit ? objEdit.role_code : "",
        rolesName: objEdit ? objEdit.role_name : "",
        rolesDesc: objEdit ? objEdit.role_desc : "",
        pageList: objEdit ? objEdit.page_list : [],
        selectStatus: objEdit ? objEdit.is_active : "Y",
        is_insert: objEdit && objEdit.is_insert === "Y" ? true : false,
        is_update: objEdit && objEdit.is_update === "Y" ? true : false,
        is_delete: objEdit && objEdit.is_delete === "Y" ? true : false,
        is_print: objEdit && objEdit.is_print === "Y" ? true : false,
        is_import: objEdit && objEdit.is_import === "Y" ? true : false,
        is_export: objEdit && objEdit.is_export === "Y" ? true : false,
        is_approve: objEdit && objEdit.is_approve === "Y" ? true : false,
        is_cancel: objEdit && objEdit.is_cancel === "Y" ? true : false,
      }}
      onSubmit={async (values) => {
        console.log("values:::", values);
        // alert(JSON.stringify(values, null, 2));
        if (props.openPopupUpdateForm) {
          console.log("update");
          await onUpdateRoles(values);
        } else {
          console.log("save");

          await onSaveRoles(values);
        }
      }}
      validationSchema={Yup.object().shape({
        rolesCode: Yup.string().required("โปรดใส่ข้อมูล"),
        rolesName: Yup.string().required("โปรดใส่ข้อมูล"),
        rolesDesc: Yup.string().required("โปรดใส่ข้อมูล"),
        // acceptTerms: Yup.bool().oneOf(
        //   [true],
        //   "Accept Terms & Conditions is required"
        // ),
        // pageList: Yup.bool().oneOf([true]),
        // tags: Yup.array().min(1).required(),
        pageList: Yup.array().min(1).required(),
        // rolesIcon: Yup.string().required("โปรดใส่ข้อมูล"),
        // rolesSequence: Yup.number().required("โปรดใส่ข้อมูล"),
        // selectRolesModule: Yup.number().required("โปรดใส่ข้อมูล"),
        // selectStatus: Yup.string().required("โปรดใส่ข้อมูล"),
        // rolesPath: Yup.string().required("โปรดใส่ข้อมูล"),
        // checked: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="manageRoles">
                <b>Management Roles</b>
              </h5>
              <button
                id="btnClose"
                type="button"
                className="close"
                onClick={() => onClosePopup()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <ErrorFocus />
            <div className="modal-body">
              <div className="container-fluid">
                <div className="form-group row">
                  <label
                    htmlFor="rolesCode"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>Roles Code</b>
                  </label>
                  <input
                    type="text"
                    id="rolesCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rolesCode}
                    maxLength="6"
                    className={
                      errors.rolesCode && touched.rolesCode
                        ? "col-4 text-input error"
                        : "col-4 text-input"
                    }
                    name="rolesCode"
                    placeholder="CODE"
                  />
                  <label
                    htmlFor="rolesName"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>Roles Name</b>
                  </label>
                  <input
                    type="text"
                    id="rolesName"
                    name="rolesName"
                    placeholder="NAME"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rolesName}
                    className={
                      errors.rolesName && touched.rolesName
                        ? "col-4 text-input error"
                        : "col-4 text-input"
                    }
                  />
                </div>
                <div className="form-group row">
                  {errors.rolesCode && touched.rolesCode && (
                    <div className="col-6 input-feedback-roles">
                      {errors.rolesCode}
                    </div>
                  )}
                  {errors.rolesName && touched.rolesName && (
                    <div className="offset-6 col-6 input-feedback-roles" style={{position:'absolute',paddingLeft:'14%'}}>
                      {errors.rolesName}
                    </div>
                  )}
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="icon"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>Roles DESC</b>
                  </label>
                  <input
                    type="text"
                    id="rolesDesc"
                    name="rolesDesc"
                    value={values.rolesDesc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // placeholder="fa fa-circle-o"
                    className={
                      errors.rolesDesc && touched.rolesDesc
                        ? "col-4 text-input error"
                        : "col-4 text-input"
                    }
                  />
                  <label
                    htmlFor="icon"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>Status</b>
                  </label>
                  <select
                    id="selectStatus"
                    name="selectStatus"
                    value={values.selectStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.selectStatus && touched.selectStatus
                        ? "form-control col-4 text-input error"
                        : "form-control col-4 text-input"
                    }
                  >
                    <option value={"Y"}>ใช้งาน</option>
                    <option value={"N"}>ไม่ใช้งาน</option>
                  </select>
                </div>
                <div className="form-group row">
                  {errors.rolesDesc && touched.rolesDesc && (
                    <div className="col-6 input-feedback-roles">
                      {errors.rolesDesc}
                    </div>
                  )}
                  {errors.selectStatus && touched.selectStatus && (
                    <div className="offset-6 col-6 input-feedback-roles" style={{position:'absolute',paddingLeft:'14%'}}>
                      {errors.selectStatus}
                    </div>
                  )}
                </div>
              </div>

              <div className="box box-info">
                <div className="box-header">Role Access</div>
                <div className="box-body">
                  <div className="form-group col-12 row">
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_insert"
                        name="is_insert"
                      />
                      <label className="form-check-label" for="is_insert">
                        Add
                      </label>
                    </div>
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_update"
                        name="is_update"
                      />
                      <label className="form-check-label" for="is_update">
                        Edit
                      </label>
                    </div>
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_delete"
                        name="is_delete"
                        // value={is_delete} checked={is_delete === "Y" ? true : false}
                      />
                      <label className="form-check-label" for="is_delete">
                        Delete
                      </label>
                    </div>
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_print"
                        name="is_print"
                        // value={is_print} checked={is_print === "Y" ? true : false}
                      />
                      <label className="form-check-label" for="is_print">
                        Print
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-12 row">
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_import"
                        name="is_import"
                        // value={is_import} checked={is_import === "Y" ? true : false}
                      />
                      <label className="form-check-label" for="is_import">
                        Import
                      </label>
                    </div>
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_export"
                        name="is_export"
                      />
                      <label className="form-check-label" for="is_export">
                        Export
                      </label>
                    </div>
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_approve"
                        name="is_approve"
                      />
                      <label className="form-check-label" for="is_approve">
                        Approve
                      </label>
                    </div>
                    <div className="col-3 form-inline form-check">
                      <Field
                        type="checkbox"
                        className="col-3"
                        id="is_cancel"
                        name="is_cancel"
                        // value={is_cancel} checked={is_cancel === "Y" ? true : false}
                      />
                      <label className="form-check-label" for="is_cancel">
                        Cancel
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box box-success">
                {/* <div className="form-group form-check">
                  <Field
                    type="checkbox"
                    name="acceptTerms"
                    id="acceptTerms"
                    className={
                      "form-check-input " +
                      (errors.acceptTerms && touched.acceptTerms
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <label htmlFor="acceptTerms" className="form-check-label">
                    Accept Terms & Conditions
                  </label>
                   <ErrorMessage
                    name="acceptTerms"
                    component="div"
                    className="invalid-feedback"
                  /> 
                </div> */}
                <div className="box-header">Select Pages</div>
                <div className="box-body">
                  {modulePageList &&
                    modulePageList.length > 0 &&
                    modulePageList.map((i, key) => {
                      return (
                        <>
                          <div className="form-group col-12 row">
                            <span style={{ color: "blue" }}>
                              {i.module_name}
                            </span>
                          </div>
                          <div className="container-fluid">
                            <div className="form-group row">
                              <FieldArray
                                name="pageList"
                                render={(arrayHelpers) => (
                                  <>
                                    {i.pages && i.pages.length > 0 &&
                                      i.pages.map((page, key2) => (
                                        <>
                                          <div
                                            className="col-3 form-inline form-check"
                                            key={page.page_id}
                                          >
                                            <input
                                              name="pageList"
                                              type="checkbox"
                                              value={page.page_id}
                                              id={page.page_id}
                                              checked={values.pageList.includes(
                                                page.page_id
                                              )}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  arrayHelpers.push(
                                                    page.page_id
                                                  );
                                                } else {
                                                  const idx =
                                                    values.pageList.indexOf(
                                                      page.page_id
                                                    );
                                                  arrayHelpers.remove(idx);
                                                }
                                              }}
                                              className={
                                                "form-control col-2 form-check-input " +
                                                (errors.pageList &&
                                                touched.pageList
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for={page.page_id}
                                            >
                                              {page.page_name}
                                            </label>
                                          </div>
                                          {i.pages.length > 4 &&
                                            (key2 + 1) % 4 == 0 && (
                                              <div className="form-group col-12"></div>
                                            )}
                                        </>
                                      ))}
                                  </>
                                )}
                              />
                              {/* {i.pages.length > 0 &&
                                i.pages.map((j, key2) => {
                                  return (
                                    <>
                                      <div className="col-3 form-inline">
                                        <Field
                                          type="checkbox"
                                          className="col-2"
                                          name="pageList"
                                          id={"check" + j.page_name}
                                          // className={
                                          //   errors.selectStatus && touched.selectStatus
                                          //     ? "form-control col-2 text-input error"
                                          //     : "form-control col-2 text-input"
                                          // }
                                          // value={j.page_id}
                                          className={
                                            "form-control col-2 form-check-input " +
                                            (errors.pageList && touched.pageList
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <label
                                          htmlFor="pageList"
                                          className="form-check-label"
                                        >
                                          {j.page_name}
                                        </label>
                                      </div>
                                      {i.pages.length > 4 &&
                                        (key2 + 1) / 4 == 1 && (
                                          <div className="form-group col-12"></div>
                                        )}
                                    </>
                                  );
                                })} */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // onClick={handleReset}
                onClick={() => onClosePopup()}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                บันทึก
              </button>
            </div>
            {/* </div>
              </div>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
}
