import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";
import $ from "jquery";
import { getMainCookie } from "../../components/utility/Config";

export default function Sidebar() {
  const {
    sideBarActive,
    setSideBarActive,
    selectMenu,
    setSelectMenu,
    contextUserInfo,
    setContextUserInfo,
  } = useContext(Context);
  const { url, path } = useRouteMatch();
  const [userInfo, setUserInfo] = useState(getMainCookie().userInfo);
  const [userPermission, setUserPermission] = useState(getMainCookie().userInfo.permissions);

  useEffect(async () => {

    if (getMainCookie()) {
      await (getMainCookie().userInfo);
      await setUserPermission(getMainCookie().userInfo.permissions);
    }

    // let pathname = window.location.pathname;
      let pathname = window.location.hash;
      if (pathname) {
        let path = pathname.split("/");

        let newModule = []
        for(let root of userPermission){
          for(let module of root.modules){
            newModule.push(module)
          }
        }
        for (let module of newModule) {
          for(let page of module.pages) {
            if (page.page_path === path[2]) {
              await $("#moduleControl"+module.module_id).removeClass("collapsed");
              await $("#moduleControl"+module.module_id).addClass("show");
              await $("#adminManage"+module.module_id).attr("aria-expanded", "true");
              await $("#" + path[2]).addClass("active");
            } else {
              await $("#" + page.page_path).removeClass("active");
            }
          }
        }
        // let elms = await document
        //   .getElementById("moduleControl0")
        //   .getElementsByTagName("li");
  
        // if (elms) {
        //   for (let item of elms) {
        //     console.log(item);
        //     for(let e of item) {
        //       console.log(e.id);
        //       if (e.id === path[2]) {
        //         await $("#moduleControl").removeClass("collapsed");
        //         await $("#moduleControl").addClass("show");
        //         await $("#adminManage").attr("aria-expanded", "true");
        //         await $("#" + path[2]).addClass("active");
        //       } else {
        //         await $("#" + e.id).removeClass("active");
        //       }
        //     }
        //     // if (item.id === path[2]) {
        //     //   await $("#moduleControl").removeClass("collapsed");
        //     //   await $("#moduleControl").addClass("show");
        //     //   await $("#adminManage").attr("aria-expanded", "true");
        //     //   await $("#" + path[2]).addClass("active");
        //     // } else {
        //     //   await $("#" + item.id).removeClass("active");
        //     // }
        //   }
        // }
      }
    
  }, []);

  const selectedMenu = (e, id,module_id) => {
    e.preventDefault();
    // let elms = document
    //   .getElementById("moduleControl"+module_id)
    //   .getElementsByTagName("li");
    //   console.log(elms);
    let elms = []

    let newModule = []
    for(let root of userPermission){
      for(let module of root.modules){
        newModule.push(module)
      }
    }
    for(let module of newModule){
      elms.push(document
      .getElementById("moduleControl"+module.module_id)
      .getElementsByTagName("li"));
    }

    for (let module of newModule) {
      if(module_id === module.module_id){
        $("#adminManage"+module.module_id).attr("aria-expanded", "true");
      }
      else{
        $("#moduleControl"+module.module_id).addClass("collapsed");
        $("#moduleControl"+module.module_id).removeClass("show");
        $("#adminManage"+module.module_id).attr("aria-expanded", "false");
      }
    }
    
    if (elms) {
      for (let modules of elms) {
        $("#adminManage"+modules.module_id).attr("aria-expanded", "false");
        for(let item of modules){
          $("#" + item.id).removeClass("active");
          
        }
      }
    }
    $("#" + id).addClass("active");
    // setSelectMenu(id);
  };

  const onClearSelectedMenu = async () => {
    
    let newModule = []
    for(let root of userPermission){
      for(let module of root.modules){
        newModule.push(module)
      }
    }

    for (let module of newModule) {
      
        $("#moduleControl"+module.module_id).addClass("collapsed");
        $("#moduleControl"+module.module_id).removeClass("show");
        $("#adminManage"+module.module_id).attr("aria-expanded", "false");
    }

    let elms = []
    for(let module of newModule){
      elms.push(document
      .getElementById("moduleControl"+module.module_id)
      .getElementsByTagName("li"));
    }
    if (elms) {
      for (let modules of elms) {
        // $("#adminManage"+modules.module_id).attr("aria-expanded", "false");
        for(let item of modules){
          $("#" + item.id).removeClass("active");
          
        }
      }
    }
  }

  return (
    <aside
      className="main-sidebar"
      style={{
        width: sideBarActive ? "250px" : "80px",
        // height: dynamicHeight+'px'
      }}
    >
      <nav id="sidebar" className={sideBarActive ? "" : "active"}>
        <div className="sidebar-header">
          <h3>
            <Link to={`${path}/home`} onClick={()=> onClearSelectedMenu()}>ระบบบริหารจัดการ Back Office</Link>
          </h3>
          {/* <strong>BS</strong> */}
          <strong>
            <img
              src="/img/logo.jpg"
              alt=""
              style={{
                // borderRadius: "50%",
                // width: "50%",
                // verticalAlign: "top",
                borderRadius: "10%",
                width: "70%",
                verticalAlign: "initial",
              }}
            />
          </strong>
        </div>
        <ul className="list-unstyled components">
          {userPermission &&
            userPermission.map((root, key) => {
              console.log(root);
              return (
                <>
                  {/* MAIN or ADMIN */}
                  <li className="headerMenu">{root.root_name}</li>
                  {/* MODULES */}
                  {root.modules &&
                    root.modules.map((module, key2) => {
                      console.log(module);
                      return (
                        <li>
                          <a
                            // href="#moduleControl"
                            href={"#moduleControl"+module.module_id}
                            data-toggle="collapse"
                            aria-expanded="false"
                            className="dropdown-toggle"
                            id={"adminManage"+module.module_id}
                          >
                            <i className={module.module_icon && module.module_icon == "" ? "fa fa-laptop" : module.module_icon} aria-hidden="true" />
                            {module.module_name}
                          </a>
                          <ul
                            className="collapse list-unstyled"
                            // id="moduleControl"
                            id={"moduleControl"+module.module_id}
                          >
                            {/* PAGES */}
                            {module &&
                              module.pages.map((page, key3) => {
                                return (
                                  <li
                                    style={{fontSize:'20px'}}
                                    id={page.page_path}
                                    onClick={(e) => selectedMenu(e, page.page_path,module.module_id)}
                                  >
                                    <Link
                                      to={`${path}/${page.page_path}`}
                                      className="nav-link"
                                    >
                                      <i
                                        className={page.page_icon}
                                        aria-hidden="true"
                                      />
                                      {page.page_name}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </li>
                      );
                    })}
                </>
              );
            })}
        </ul>
        {/* <ul className="list-unstyled components">
          <li className="headerMenu">ADMIN MANAGEMENT</li>
          <li>
            <a
              href="#moduleControl"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
              id="adminManage"
            >
              <i className="fa fa-laptop" aria-hidden="true" />
              MODULE CONTORL
            </a>
            <ul className="collapse list-unstyled" id="moduleControl">
              <li id="module" onClick={(e) => selectedMenu(e, "module")}>
                <Link to={`${path}/module`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  MODULE
                </Link>
              </li>
              <li id="pages" onClick={(e) => selectedMenu(e, "pages")}>
                <Link to={`${path}/pages`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  PAGES
                </Link>
              </li>
              <li id="roles" onClick={(e) => selectedMenu(e, "roles")}>
                <Link to={`${path}/roles`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  จัดการสิทธิ์ผู้ใช้งาน
                </Link>
              </li>
              <li id="users" onClick={(e) => selectedMenu(e, "users")}>
                <Link to={`${path}/users`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  ผู้ใช้งาน
                </Link>
              </li>
            </ul>
          </li>
          <li className="headerMenu">MAIN NAVIGATION</li>
        </ul> */}
        {/* <ul className="list-unstyled CTAs">
            <li>
              <a
                href="https://bootstrapious.com/tutorial/files/sidebar.zip"
                className="download"
              >
                Download source
              </a>
            </li>
            <li>
              <a
                href="https://bootstrapious.com/p/bootstrap-sidebar"
                className="article"
              >
                Back to article
              </a>
            </li>
          </ul> */}
      </nav>
    </aside>
  );
}
