import React, { useEffect, useState } from "react";
import RCPagination from "../../utility/RCPagination";
import FormModule from "./FormModule";
import { getFetch } from "../../utility/CallApi";
import {
  url_getModuleList,
  url_deleteModule,
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_getModuleInfo,
} from "../../utility/Config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { AlertConfirm, Alert } from "../../utility/AlertBar";
import Swal from "sweetalert2";
import $ from "jquery";

export default function Module() {
  const { url, path } = useRouteMatch();
  const { name } = useParams();

  const [openPopupAddForm, setOpenPopupAddForm] = useState(false);
  const [openPopupUpdateForm, setOpenPopupUpdateForm] = useState(false);

  const [moduleList, setModuleList] = useState([]);

  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalResult, setTotalResult] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [objEditModule, setObjEditModule] = useState(null);

  useEffect(() => {
    
    getModuleList();
  }, []);

  const getModuleList = async () => {
    let body = {
      filter: textSearch,
      orderField: "module_id",
      orderType: "desc",
      pageNo: curPage,
      pageSize: pageSize,
    };
    let data = await getFetch(
      url_getModuleList,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("RESULT_TOTAL:::", data.RESULT_TOTAL);
    setModuleList(data.RESULT_DATA);
    setTotalResult(data.RESULT_TOTAL);
  };

  const onChangePage = async (currentPage) => {
    let body = {
      filter: textSearch,
      orderField: "module_id",
      orderType: "desc",
      pageNo: currentPage,
      pageSize: pageSize,
    };
    let data = await getFetch(
      url_getModuleList,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    setModuleList(data.RESULT_DATA);
    setCurPage(currentPage);
    setTotalResult(data.RESULT_TOTAL);
  };

  const onDeleteModule = async (e, i) => {
    e.preventDefault();

    AlertConfirm("ต้องการลบ Module: " + i.module_name+" \n  ใช่หรือไม่?").then(async (isOk) => {
      if (isOk) {
        let data = {
          module_id: i.module_id,
        };
        await getFetch(
          url_deleteModule,
          METHOD_POST,
          DEFAULT_HEADERS,
          data,
          null
        ).then(async (res) => {
          if (res.RESULT_STATUS === "000") {
            Alert("ลบ Module", "ลบ Module เรียบร้อยแล้ว", "success");
            await getModuleList();
          }
        });
      }
    });
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter") {
      getModuleList();
    }
  };

  const onKeyUp = async (val) => {
    let body = {
      filter: val,
      orderField: "module_id",
      orderType: "desc",
      pageNo: 1,
      pageSize: pageSize,
    };
    let data = await getFetch(
      url_getModuleList,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("RESULT_TOTAL:::", data.RESULT_TOTAL);
    setModuleList(data.RESULT_DATA);
    setTotalResult(data.RESULT_TOTAL);
  };

  const onClosePopup = async () => {
    console.log("onClosePopup:::");
    // document.getElementById("exampleModal").classList.remove("show", "d-block");
    // document
    //   .querySelectorAll(".modal-backdrop")
    //   .forEach((el) => el.classList.remove("modal-backdrop"));
    await window.$("#modalFormModule").modal("hide");
    await setOpenPopupAddForm(false);
    await setOpenPopupUpdateForm(false);
    await getModuleList();
  };

  const onEditModule = async (id) => {
    let data = await getModuleInfo(id);
    await setObjEditModule(data);
    await setOpenPopupUpdateForm(true);
  };

  const getModuleInfo = async (id) => {
    let body = {
      module_id: id,
    };
    let data = await getFetch(
      url_getModuleInfo,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    if (data && data.RESULT_DATA.length > 0) {
      return data.RESULT_DATA[0];
    } else {
      return;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h4>จัดการ Module</h4>
        </div>
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb" style={{padding: '0px',backgroundColor: '#FFF',justifyContent:'end'}}>
              <li className="breadcrumb-item">
                <Link to={`/page/home`}>
                <i className="fa fa-home" aria-hidden="true"/>หน้าหลัก
                </Link>
                
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {name}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <hr style={{marginTop:'0px'}} />
      <div className="row">
        <div className="col-1 col-md-1">
          <label
            htmlFor="search"
            className="col-form-label d-flex justify-content-end"
          >
            Search :
          </label>
        </div>
        <div className="col-5 col-md-5">
          <input
            type="text"
            className="form-control"
            id="search"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            onKeyPress={(e) => onKeyPress(e)}
            onKeyUp={(e) => onKeyUp(e.target.value)}
          />
        </div>
        <div className="col-6 col-md-6  d-flex justify-content-end">
          <button
            id="#modalFormModule"
            type="button"
            className="d-flex btn btn-success align-items-center"
            data-toggle="modal"
            data-target="#modalFormModule"
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => setOpenPopupAddForm(true)}
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ paddingRight: "5px" }}
            />
            Module
          </button>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalFormModule"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalFormModule"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {openPopupAddForm && <FormModule onClosePopup={onClosePopup} />}
            {openPopupUpdateForm && (
              <FormModule
                onClosePopup={onClosePopup}
                objEditModule={objEditModule}
                openPopupUpdateForm={openPopupUpdateForm}
              />
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="table-responsive">
        <table className="table table-striped table-hover  table-sm">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th scope="col">#</th>
              <th scope="col">Code</th>
              <th scope="col">Name</th>
              {/* <th scope="col">ประเภทการแสดง</th> */}
              <th scope="col">ลำดับการแสดง</th>
              <th scope="col">สถานะ</th>
              <th scope="col" style={{ width: "7%" }}>
                Edit
              </th>
              <th scope="col" style={{ width: "7%" }}>
                Del
              </th>
            </tr>
          </thead>
          <tbody>
            {moduleList &&
              moduleList.length > 0 &&
              moduleList.map((i, key) => {
                return (
                  <tr
                    key={key}
                    className="align-middle"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td scope="row" className="align-middle">
                      {`${(curPage - 1) * pageSize + key + 1}`}
                    </td>
                    <td className="align-middle">{i.module_code}</td>
                    <td className="align-middle">{i.module_name}</td>
                    {/* <td className="align-middle">
                      {i.module_type === "1" ? "BACK OFFICE" : "FRONT OFFICE"}
                    </td> */}
                    <td className="align-middle">{i.module_order}</td>
                    <td className="align-middle">
                      {i.is_active === "Y" ? "ใช้งาน" : "ไม่ใช้งาน"}
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-link"
                        // onClick={(e) => onEditModule(e, i)}
                        id="#modalFormModule"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalFormModule"
                        data-backdrop="static"
                        data-keyboard="false"
                        onClick={(e) => onEditModule(i.module_id)}
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.4rem",
                            verticalAlign: "middle",
                          }}
                        />
                      </button>
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-link"
                        onClick={(e) => onDeleteModule(e, i)}
                      >
                        <i
                          className="fa fa-trash-o"
                          aria-hidden="true"
                          style={{
                            color: "red",
                            fontSize: "1.4rem",
                            verticalAlign: "middle",
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <RCPagination
        pageSize={pageSize}
        // onChange={onChangePage}
        current={curPage}
        total={totalResult}
        onSelect={onChangePage}
        // onShowSizeChange={this.onPageSizeChange.bind(this)}
      />
      {/* <div className="form-group col-12 row" style={{paddingRight:'0px'}}>
          <div className="col-6">Showing 1 to 10 of 12 entries</div>
            <nav aria-label="Page navigation example" className="col-6" style={{paddingRight:'0px'}}>
              <ul className="pagination justify-content-end">
                <li className="page-item disabled">
                  <a className="page-link" href="#" tabindex="-1">
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
        </div> */}
    </>
  );
}
