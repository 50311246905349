const logSystem = "BACK OFFICE"
const logModule = "BACK OFFICE"

function log(message) {
    let logMsg = logMessage(message)
    logMsg.level = "log"
    console.log(JSON.stringify(logMsg))
}

function info(message) {
    let logMsg = logMessage(message)
    logMsg.level = "info"
    console.log(JSON.stringify(logMsg))
}

function error(message, e) {
    let logMsg = {}
    if (message instanceof Error) {
        logMsg = logMessage(message.stack)
    } else {
        logMsg = logMessage(message)
        if (e) {
            logMsg.message += " : " + e.stack
        }
    }

    logMsg.level = "error";
    console.log(JSON.stringify(logMsg))
}

function debug(message) {
    let logMsg = logMessage(message)
    logMsg.level = "debug"
    console.log(JSON.stringify(logMsg))
}

function logMessage(message) {
    let logMsg = {}
    logMsg.message = message
    logMsg.timestamp = getTimestamp()
    logMsg.system = logSystem
    logMsg.module = logModule
    logMsg["@suffix"] = logModule
    return logMsg;
}

function getTimestamp() {
    var timezone_offset_min = new Date().getTimezoneOffset(),
        offset_hrs = parseInt(Math.abs(timezone_offset_min / 60)),
        offset_min = Math.abs(timezone_offset_min % 60),
        timezone_standard

    if (offset_hrs < 10)
        offset_hrs = '0' + offset_hrs

    if (offset_min < 10)
        offset_min = '0' + offset_min

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
        timezone_standard = '+' + offset_hrs + ':' + offset_min
    else if (timezone_offset_min > 0)
        timezone_standard = '-' + offset_hrs + ':' + offset_min
    else if (timezone_offset_min === 0)
        timezone_standard = 'Z'

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    // console.log(timezone_standard);

    var dt = new Date(),
        current_date = dt.getDate(),
        current_month = dt.getMonth() + 1,
        current_year = dt.getFullYear(),
        current_hrs = dt.getHours(),
        current_mins = dt.getMinutes(),
        current_secs = dt.getSeconds(),
        current_datetime

    // Add 0 before date, month, hrs, mins or secs if they are less than 0
    current_date = current_date < 10 ? '0' + current_date : current_date
    current_month = current_month < 10 ? '0' + current_month : current_month
    current_hrs = current_hrs < 10 ? '0' + current_hrs : current_hrs
    current_mins = current_mins < 10 ? '0' + current_mins : current_mins
    current_secs = current_secs < 10 ? '0' + current_secs : current_secs

    // Current datetime
    // String such as 2016-07-16T19:20:30
    current_datetime = current_year + '-' + current_month + '-' + current_date + 'T' + current_hrs + ':' + current_mins + ':' + current_secs;
    return current_datetime + timezone_standard
}

module.exports = {
    info,
    error,
    debug,
    log
}