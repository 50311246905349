import React, { useContext } from "react";
import { Context } from "../../App";

export default function Footer() {
  const { sideBarActive, setSideBarActive } = useContext(Context);
  return (
    <div
      className="main-footer text-center p-2"
      style={{
        marginLeft: sideBarActive ? "250px" : "80px",
        transition: "all 0.3s",
      }}
    >
      © 2021 MBSOLUTIONS – ALL RIGHTS RESERVED
    </div>
  );
}
