import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../App";
import FormResetPassword from "./FormResetPassword";
import "./userButton.css";
import { getFetch } from "../../components/utility/CallApi";
import {
  DEFAULT_HEADERS,
  METHOD_POST,
  url_getUserInfo,
  deleteCookie,
  getMainCookie,
  BASE_URL,
} from "../../components/utility/Config";

export default function Header() {
  const {
    sideBarActive,
    setSideBarActive,
    contextUserInfo,
    setContextUserInfo,
  } = useContext(Context);
  const [openPopupResetPasswordForm, setOpenPopupResetPasswordForm] =
    useState(false);
  const [objResetPass, setobjResetPass] = useState(null);
  const [userInfo, setUserInfo] = useState(getMainCookie().userInfo);

  const toggleSidebar = (e) => {
    e.preventDefault();
    setSideBarActive(!sideBarActive);
  };

  const onClosePopup = async () => {
    await window.$("#modalFormResetPassword2").modal("hide");
    await setOpenPopupResetPasswordForm(false);
  };

  const getUsersInfo = async (id) => {
    let body = {
      get_user_id: id,
    };
    let data = await getFetch(
      url_getUserInfo,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    if (data.RESULT_DATA.length > 0) {
      return data.RESULT_DATA[0];
    } else {
      return;
    }
  };

  const onResetPassword = async () => {
    let data = await getUsersInfo(getMainCookie().userInfo.user_id);
    if (data) {
      await setobjResetPass(data);
      await setOpenPopupResetPasswordForm(true);
    }
  };
  return (
    <nav className="navbar navbar-dark" style={{ backgroundColor: "#401f68" }}>
      <button
        type="button"
        id="sidebarCollapse"
        className="btn btn-info"
        onClick={(e) => toggleSidebar(e)}
        style={{
          marginLeft: sideBarActive ? "250px" : "80px",
          transition: "all 0.3s",
        }}
      >
        <i className="fa fa-align-justify" aria-hidden="true"></i>
      </button>
      <div
        class="dropdown"
        style={{
          position: "inherit",
          // width: "250px",
          textAlign: "-webkit-center",
          top: "-5px",
          color: "#FFF",
        }}
      >
        <button
          type="button"
          class="btn btn-link dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ color: "#FFF !important" }}
          id="dropdownMenu2"
        >
          {/* <a
            id="userButton"
            className="dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
            // style={{ cursor: "pointer" }}
          > */}
            <img
              // src={userInfo && userInfo.user_img ? "/img/profileImage/"+userInfo.user_img : "/img/logo.jpg"}
              src={
                userInfo && userInfo.user_img
                  ? BASE_URL + userInfo.user_img
                  : "/img/logo.jpg"
              }
              className="user-image"
              // alt="User Image"
              style={{
                borderRadius: "10%",
                // width: "20%",
                maxHeight: "35px",
                // verticalAlign: "top",
                paddingRight: "5px",
              }}
            />
            <span
              className="hidden-xs cut-text"
              style={{
                marginRight: "5px",
                paddingRight: "20px",
                color: "#FFF",
              }}
            >
              {userInfo && userInfo.user_name}
            </span>
          {/* </a> */}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2" style={{right: '0', left: 'auto'}}>
          <div className="col-12 dropdown-item">
            <button
              // onClick="resetPassword()"
              className="btn btn-link btn-flat"
              style={{ textDecoration: "none" }}
              id="#modalFormResetPassword2"
              type="button"
              data-toggle="modal"
              data-target="#modalFormResetPassword2"
              data-backdrop="static"
              data-keyboard="false"
              onClick={(e) => onResetPassword()}
            >
              <i className="fa fa-key" /> เปลี่ยนรหัสผ่าน
            </button>
          </div>
          <div className="col-12 dropdown-item">
            <Link
              to={`/`}
              className="btn btn-link btn-flat"
              style={{ textDecoration: "none" }}
              onClick={() => deleteCookie("backoffice")}
            >
              <i className="fa fa-power-off" /> ออกจากระบบ
            </Link>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalFormResetPassword2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalFormResetPassword2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            {openPopupResetPasswordForm && (
              <FormResetPassword
                onClosePopup={onClosePopup}
                objResetPass={objResetPass}
                openPopupResetPasswordForm={openPopupResetPasswordForm}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
