import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import "./css/login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { url_login, METHOD_POST, DEFAULT_HEADERS, getMainCookie, } from "../utility/Config";
import { getFetch } from "../utility/CallApi";
import { Context } from "../../App";
import { AlertCommit } from "../utility/AlertBar";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function Login() {
  const { contextUserInfo,setContextUserInfo } = useContext(Context);

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [startPage, setStartPage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const keyPress = (e) => {
    if (e.which === 13) {
      login();
    } else {
      return;
    }
  };

  const login = async (username, password) => {
    let body = {
      user_login: username,
      user_password: password,
    };
    let data = await getFetch(
      url_login,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    if(data){
      if (data.RESULT_STATUS === "000") {

        cookies.set(
          "backoffice",
          JSON.stringify({
            userInfo: data.RESULT_DATA[0]
          }),
          { path: "/", maxAge: 24 * 60 * 60 * 1000 }
        );
        setContextUserInfo(data.RESULT_DATA[0])
        setStartPage("/page/home");
        setRedirectToReferrer(true);
      } else {
        AlertCommit(false, 'เกิดข้อผิดพลาด \n กรุณาติดต่อผู้ดูแลระบบ')
      }
    }else{
      AlertCommit(false, 'เกิดข้อผิดพลาด \n กรุณาติดต่อผู้ดูแลระบบ')
    }
    
  };

  return (
    <>
      {redirectToReferrer ? (
        <Redirect to={startPage} />
      ) : (
        <div className="backgroundImage">
          <div className="wrapperLogin">
            <div className="logo">
              {" "}
              <img src="./img/logo.jpg" alt="" />{" "}
            </div>
            <div className="text-center mt-4 name"> ระบบบริหารจัดการ </div>
            <div className="text-center mt-4 name"> Back Office </div>

            <Formik
              initialValues={{
                userName: "",
                password: "",
              }}
              onSubmit={async (values) => {
                console.log("values:::", values);
                await setUsername(values.userName);
                await setPassword(values.password);
                await login(values.userName, values.password);
                // await onSaveModule(values);
              }}
              validationSchema={Yup.object().shape({
                userName: Yup.string().required("โปรดใส่ยูสเซอร์เนม"),
                password: Yup.string().required("โปรดใส่พาสเวิด"),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="p-3 mt-3">
                      {errors.userName && touched.userName && (
                        <div className="input-user-name">{errors.userName}</div>
                      )}
                      <div className="form-field d-flex align-items-center">
                        <span style={{ fontSize: "1.5rem" }}>
                          <i className="fa fa-user" aria-hidden="true" />
                        </span>
                        <input
                          type="text"
                          name="userName"
                          id="userName"
                          placeholder="Username"
                          onChange={handleChange}
                          value={values.userName}
                        />
                      </div>
                      {errors.password && touched.password && (
                        <div className="input-user-name">{errors.password}</div>
                      )}
                      <div className="form-field d-flex align-items-center">
                        <span style={{ fontSize: "1.5rem" }}>
                          <i className="fa fa-key" aria-hidden="true" />
                        </span>
                        <input
                          type="password"
                          name="password"
                          id="pwd"
                          onChange={handleChange}
                          placeholder="Password"
                          value={values.password}
                          // onKeyPress={(e) => keyPress(e)}
                        />
                      </div>
                      <br />
                      <button
                        type="submit"
                        className="btn mt-3"
                        disabled={isSubmitting}
                      >
                        เข้าสู่ระบบ
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
            <div className="text-center fs-6">
              {" "}
              {/* <a href="#">Forget password?</a> or <a href="#">Sign up</a>{" "} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
