
import Cookies from 'universal-cookie';


const cookies = new Cookies();
const $ = window.$;

export function getMainCookie(isNotGotoLogin) {

  
    if (cookies.get('backoffice') && cookies.get('backoffice') != null) {

        return (cookies.get('backoffice'));
    }
    else {
        if (isNotGotoLogin == undefined || isNotGotoLogin == false) {
            document.location.href = "/";
        }

        return { userInfo: null };
    }


}
export function deleteCookie(name) {
    cookies.remove(name);
}

export const DEFAULT_HEADERS = {
  "Content-Type": "application/json;charset=utf-8",
  Accept: "application/json",
};

export const METHOD_GET = "GET";
export const METHOD_POST = "POST";

//http://localhost:5000
//https://backend.mbsolutions.co.th

export const BASE_URL = "https://backend.mbsolutions.co.th/"
// export const BASE_URL = "http://localhost:5000/"

//login
export const url_login = BASE_URL+"login";

// Module
export const url_getModuleList = BASE_URL+"getModuleList";
export const url_deleteModule = BASE_URL+"deleteModule";
export const url_newModule = BASE_URL+"newModule";
export const url_getModuleInfo = BASE_URL+"getModuleInfo";
export const url_updateModule = BASE_URL+"updateModule"
export const url_getModulePageListAll = BASE_URL+"getModulePageListAll"
export const url_getModuleListAll = BASE_URL+"getModuleListAll"


// Pages
export const url_getPageList = BASE_URL+"getPageList"
export const url_newPage = BASE_URL+"newPage"
export const url_updatePage = BASE_URL+"updatePage"
export const url_getPageInfo = BASE_URL+"getPageInfo"
export const url_deletePage = BASE_URL+"deletePage"

// roles
export const url_getRoleList = BASE_URL+"getRoleList"
export const url_newRoles = BASE_URL+"newRole"
export const url_updateRoles = BASE_URL+"updateRole"
export const url_getRoleInfo = BASE_URL+"getRoleInfo"
export const url_deleteRole = BASE_URL+"deleteRole"
export const url_getRoleListAll = BASE_URL+"getRoleListAll"


// users
export const url_getUserList = BASE_URL+"getUserList"
export const url_newUser = BASE_URL+"newUser"
export const url_updateUser = BASE_URL+"updateUser"
export const url_getUserInfo = BASE_URL+"getUserInfo"
export const url_deleteUser = BASE_URL+"deleteUser"
export const url_uploadProfileImage = BASE_URL+"uploadProfileImage"

//changePassword
export const url_changePassword = BASE_URL+"changePassword"
