import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";

import Content from "./content/Content";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

export default function Layout() {
  const { url, path } = useRouteMatch();
  
  return (
    <>
      <Header />
      <Sidebar />
      <Route path={`${path}/:name`}>
        <Content />
      </Route>
      <Footer />
    </>
  );
}
