import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import Login from "./components/login/Login";
import Content from "./layout/content/Content";
import Footer from "./layout/footer/Footer";
import Header from "./layout/header/Header";
import Layout from "./layout/Layout";
import "./App.css";
import React, { useContext, useState } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom/cjs/react-router-dom.min";

const Context = React.createContext(null);


function App() {

  const [sideBarActive,setSideBarActive] = useState(true)
  const [selectMenu,setSelectMenu] = useState("")
  const [contextUserInfo,setContextUserInfo] = useState(null)

  return (
    <>
      <Context.Provider value={{sideBarActive ,setSideBarActive,selectMenu,setSelectMenu,contextUserInfo,setContextUserInfo}}>
        <HashRouter>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/page">
              <Layout />
            </Route>
          </Switch>
        </HashRouter>
      </Context.Provider>
    </>
  );
}

export {Context}
export default App;