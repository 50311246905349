import React, { Component } from "react";
//import { Alert ,AlertList} from 'react-bs-notifier';
import Swal from "sweetalert2";
// import 'sweetalert2/src/sweetalert2.scss'

export function AlertCommit(isSuccess, msg) {
  if (isSuccess) {
    Swal.fire({
      position: "center",
      icon: "success",
      title: msg == "" ? "บันทึกเรียบร้อยแล้ว" : msg,
      showConfirmButton: false,
      timer: 1500,
    });
  } else {
    Swal.fire({
      position: "center",
      icon: "error",
      title: msg == "" ? "บันทึกไม่สำเร็จ" : msg,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}

export function AlertConfirm(title, msg) {
  var promise = new Promise((resolve, reject) => {
    Swal.fire({
      // html: msg?msg:"ที่จะจัดการข้อมูล",
      title: title !== "" ? title : "คุณแน่ใจหรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });

  return promise;
}

export function Alert(title, text, icon) {
  Swal.fire({
    //position: 'top-end',
    // type: 'info',
    icon: icon,
    title: title,
    html: text,
    timer: 1000,
  });
}

// export function AlertCommit(isSuccess,msg){
//     if(isSuccess){
//         Swal({
//             position: 'top-end',
//             type: 'success',
//             title: !msg?'บันทึกเรียบร้อยแล้ว':msg,
//             showConfirmButton: false,
//             showCloseButton: true,
//             // timer: 1000
//           })
//     }else{
//         Swal({
//             position: 'top-end',
//             type: 'error',
//             title: 'แย่จัง!',
//             text:  !msg?'ระบบเกิดข้อผิดพลาด':'ระบบเกิดข้อผิดพลาด '+msg,
//           })
//     }

// }

// export function AlertCommit2(isSuccess,msg){
//     if(isSuccess){
//         Swal({
//             position: 'top-end',
//             type: 'success',
//             title: !msg?'บันทึกเรียบร้อยแล้ว':msg,
//             showConfirmButton: false,
//             showCloseButton: true,
//         })
//     }else{
//         Swal({
//             position: 'top-end',
//             type: 'error',
//             title: 'แย่จัง!',
//             text:  !msg?'ระบบเกิดข้อผิดพลาด':'ระบบเกิดข้อผิดพลาด '+msg,
//         })
//     }

// }

// export function Alert(title,text){
//     Swal({
//         //position: 'top-end',
//         type: 'info',
//         title: title,
//         html:  text

//       })

// }

// export function AlertConfirm(title,msg){
//     var promise = new Promise( (resolve, reject) => {
//     Swal({
//         title: title?title:"คุณแน่ใจหรือไม่?",
//         html: msg?msg:"ที่จะจัดการข้อมูล",
//         type: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#007bff',
//         cancelButtonColor: '#dc3545',
//         confirmButtonText: 'ตกลง',
//         cancelButtonText: "ยกเลิก"
//       }).then((result) => {
//         if (result.value) {
//             resolve(true);
//         }else{
//             resolve(false);
//         }
//       })
//     });

//     return promise;

// }

// class AlertBar extends Component {
//     constructor(props) {
// 		super(props);

// 		this.state = {
//             alerts: this.getAlerts(),
//             timeout:3000
// 		};
//     }
//     componentDidMount(){
//         AlertCommit(this.props.status,this.props.msg);
//     }

//     componentDidUpdate(){
//         this.state.alerts= this.getAlerts();
//         this.state.timeout= 3000;

//     }

//     getAlerts(){
//         return this.props.status?[{  type: "success",
//             headline: ":) Success. ",
//             message: "",
//             id: "msg1"
//         }]:
//         [{  type: "danger",
//             headline: ":( Fail! ",
//             message: "",
//             id: "msg2"
//         }]
//     }

//     onAlertDismissed(alert) {
//         console.log(alert)
//         this.setState({
//             alerts: [],
//             timeout: 0
//         });

//     }
//     render () {
//         const { status } = this.props;
//         return (

//             <div style={{position:'absolute',right:10,top:70,zIndex:5}}>
//             {/* {status &&

//                 <AlertList alerts={this.state.alerts}   timeout={this.state.timeout} onDismiss={this.onAlertDismissed.bind(this)}/>

//             }
//             {!status &&
//                  <AlertList alerts={this.state.alerts}  timeout={0} onDismiss={this.onAlertDismissed.bind(this)}/>
//             } */}

//             </div>
//         )
//     }
// }
// AlertBar.defaultProps = {
//     status:true
// }

// AlertBar.propTypes = {
//     status: PropTypes.bool,
// }
// export default AlertBar
