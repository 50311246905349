import React, { useEffect, useState } from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
// import './css/Pagination.css';

export default function RCPagination(props) {

  const [pageSize, setPageSize] = useState(props.pageSize);
  const [currentPage, setCurrentPage] = useState(props.current);
  const [total, setTotal] = useState(props.total);
  // const [collection, setCollection] = React.useState(
  //   cloneDeep(allData.slice(0, pageSize))
  // );

  useEffect(() => {
    console.log(currentPage);
    console.log(pageSize);
    console.log(total);
    console.log('totalTest:::', props);
    // setTotal(props.total);
    // setCurrentPage(props.current);
    // setPageSize(props.pageSize);
    // if (!value) {
    //   updatePage(1);
    // } else {
    //   searchData.current(value);
    // }
  }, [pageSize,currentPage]);

  // const searchData = React.useRef(
  //   throttle(val => {
  //     const query = val.toLowerCase();
  //     setCurrentPage(1);
  //     const data = cloneDeep(
  //       allData
  //         .filter(item => item.name.toLowerCase().indexOf(query) > -1)
  //         .slice(0, pageSize)
  //     );
  //     setCollection(data);
  //   }, 400)
  // );

  const updatePage = p => {
    console.log('updatePage',p);
    setCurrentPage(p);
    const to = pageSize * p;
    // const from = to - pageSize;
    // setCollection(cloneDeep(allData.slice(from, to)));
  };

  const onChange = (page) => {
    props.onSelect(page);
    setCurrentPage(page);
  }

  const onShowSizeChange = (current, pageSize) => {
    this.setState({ pageSize });
    this.props.onShowSizeChange(current, pageSize);
  }

  return (
    <div className="container-fluid">
      <Pagination
        pageSize={pageSize}
        onChange={onChange}
        current={currentPage}
        total={props.total}
        showTotal={(total, range) =>
          `${range[0]} - ${range[1]} of ${total} items`
        }
        style={{ marginBottom: '20px'}}
      />
    </div>
  );
}
