import React, { useEffect, useState } from "react";
import "./css/FormModule.css";
import { Formik } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import {
  url_getModuleList,
  url_deleteModule,
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_newModule,
  url_updateModule
} from "../../utility/Config";
import { getFetch } from "../../utility/CallApi";
import { AlertCommit } from "../../utility/AlertBar";

export default function FormModule(props) {
  const [objEdit, setObjEdit] = useState(props.objEditModule);
  useEffect(() => {
    setObjEdit(props.objEditModule);
    return () => {
      setObjEdit(null);
    };
  }, [props.objEditModule]);

  const onSaveModule = async (e) => {
    console.log("props:::", props);

    let body = {
      module_code: e.moduleCode,
      module_name: e.moduleName,
      module_icon: e.moduleIcon,
      module_order: e.moduleSequence,
      root_id: e.selectRootModule,
      is_active: e.selectStatus,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_newModule,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onUpdateModule = async (e) => {
    console.log("onUpdateModule:::", props);

    let body = {
      module_code: e.moduleCode,
      module_name: e.moduleName,
      module_icon: e.moduleIcon,
      module_order: e.moduleSequence,
      root_id: e.selectRootModule,
      is_active: e.selectStatus,
      module_id: objEdit.module_id,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_updateModule,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onClosePopup = async () => {
    await props.onClosePopup();
  };

  return (
    <Formik
      // initialValues={{
      //   moduleCode: "",
      //   moduleName: "",
      //   moduleIcon: "",
      //   moduleSequence: "",
      //   selectRootModule: 1,
      //   selectStatus: "Y",
      // }}
      initialValues={{
        moduleCode: objEdit ? objEdit.module_code : "",
        moduleName: objEdit ? objEdit.module_name : "",
        moduleIcon: objEdit ? objEdit.module_icon : "",
        moduleSequence: objEdit ? objEdit.module_order : "",
        selectRootModule: objEdit ? objEdit.root_id : "2",
        selectStatus: objEdit ? objEdit.is_active : "Y",
      }}
      onSubmit={async (values) => {
        console.log("values:::", values);
        if (props.openPopupUpdateForm) {
          console.log('update');
          await onUpdateModule(values);
        } else {
          console.log('save');

          await onSaveModule(values);
        }
      }}
      validationSchema={Yup.object().shape({
        moduleCode: Yup.string().required("โปรดใส่ข้อมูล"),
        moduleName: Yup.string().required("โปรดใส่ข้อมูล"),
        moduleIcon: Yup.string().required("โปรดใส่ข้อมูล"),
        moduleSequence: Yup.number().required("โปรดใส่ข้อมูล"),
        selectRootModule: Yup.number().required("โปรดใส่ข้อมูล"),
        selectStatus: Yup.string().required("โปรดใส่ข้อมูล"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            {/* <div
              className={"modal fade"}
              id="exampleModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="manageModule"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content"> */}
            <div className="modal-header">
              <h5 className="modal-title" id="manageModule">
                <b>Management Module</b>
              </h5>
              <button
                id="btnClose"
                type="button"
                className="close"
                // data-dismiss="modal"
                // aria-label="Close"
                // disabled={!dirty || isSubmitting}
                onClick={() => onClosePopup()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group col-12 row">
                <label
                  htmlFor="moduleCode"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Module Code</b>
                </label>
                <input
                  type="text"
                  id="moduleCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.moduleCode}
                  className={
                    errors.moduleCode && touched.moduleCode
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                  name="moduleCode"
                  placeholder="CODE"
                />
                {errors.moduleCode && touched.moduleCode && (
                  <div className="input-feedback">{errors.moduleCode}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="moduleName"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Module Name</b>
                </label>
                <input
                  type="text"
                  id="moduleName"
                  placeholder="NAME"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.moduleName}
                  className={
                    errors.moduleName && touched.moduleName
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                />
                {errors.moduleName && touched.moduleName && (
                  <div className="input-feedback">{errors.moduleName}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="icon"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Module Icon</b>
                </label>
                <input
                  type="text"
                  id="moduleIcon"
                  value={values.moduleIcon}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="fa fa-home"
                  className={
                    errors.moduleName && touched.moduleName
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                />
                {errors.moduleIcon && touched.moduleIcon && (
                  <div className="input-feedback">{errors.moduleIcon}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="seq"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Module Sequence</b>
                </label>
                <input
                  id="moduleSequence"
                  type="number"
                  placeholder="Sequence"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.moduleSequence}
                  className={
                    errors.moduleSequence && touched.moduleSequence
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                />
                {errors.moduleSequence && touched.moduleSequence && (
                  <div className="input-feedback">{errors.moduleSequence}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="selectRootModule"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Root module</b>
                </label>
                <select
                  id="selectRootModule"
                  value={values.selectRootModule}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.selectRootModule && touched.selectRootModule
                      ? "form-control col-7 text-input error"
                      : "form-control col-7 text-input"
                  }
                >
                  <option defaultValue value="2">
                    ADMIN MANAGEMENT
                  </option>
                  <option value="1">MAIN NAVIGATION</option>
                </select>
                {errors.selectRootModule && touched.selectRootModule && (
                  <div className="input-feedback">
                    {errors.selectRootModule}
                  </div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="selectRootModule"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Status</b>
                </label>
                <select
                  id="selectStatus"
                  value={values.selectStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.selectStatus && touched.selectStatus
                      ? "form-control col-7 text-input error"
                      : "form-control col-7 text-input"
                  }
                >
                  <option  value={"Y"}>
                    ใช้งาน
                  </option>
                  <option value={"N"}>ไม่ใช้งาน</option>
                </select>
                {errors.selectStatus && touched.selectStatus && (
                  <div className="input-feedback">{errors.selectStatus}</div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // onClick={handleReset}
                onClick={() => onClosePopup()}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                บันทึก
              </button>
            </div>
            {/* </div>
              </div>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
}
