import React, { useEffect, useState } from "react";
import "./css/FormPages.css";
import { Formik } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import {
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_newPage,
  url_updatePage,
  url_getModuleListAll,
} from "../../utility/Config";
import { getFetch } from "../../utility/CallApi";
import { AlertCommit } from "../../utility/AlertBar";

export default function FormPage(props) {

  const [objEdit, setObjEdit] = useState(props.objEditPages);
  const [moduleList, setModuleList] = useState([]);
  
  useEffect(async () => {
    await setObjEdit(props.objEditPages);
    await getModuleListAll();
    return () => {
      setObjEdit(null);
    };
  }, [props.objEditPages]);

  const getModuleListAll = async () => {
    let body = {};
    let data = await getFetch(
      url_getModuleListAll,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      let newListModule = [];
      let obj = data.RESULT_DATA;
      obj.map((i) => {
        let newLabel = "(" + i.module_code + ")" + " " + i.module_name;
        newListModule.push({ value: i.module_id, label: newLabel });
      });
      await setModuleList(newListModule);
    } else if (data && data.RESULT_STATUS === "999") {
      return;
    }
  };

  const onSavePage = async (e) => {
    console.log("props:::", props);

    let body = {
      page_code: e.pageCode,
      page_name: e.pageName,
      page_icon: e.pageIcon,
      page_seq: e.pageSequence,
      // root_id: e.selectRootpage,
      is_active: e.selectStatus,
      page_path: e.pagePath,
      module_id: e.selectPageModule,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_newPage,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onUpdatePage = async (e) => {
    console.log("onUpdatePage:::", props);

    let body = {
      page_code: e.pageCode,
      page_name: e.pageName,
      page_icon: e.pageIcon,
      page_seq: e.pageSequence,
      module_id: e.selectPageModule,
      is_active: e.selectStatus,
      page_id: objEdit.page_id,
      page_path: e.pagePath,
      // module_id: e.module_id,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_updatePage,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onClosePopup = async () => {
    await props.onClosePopup();
  };

  return (
    <Formik
      // initialValues={{
      //   pageCode: "",
      //   pageName: "",
      //   pageIcon: "",
      //   pageSequence: "",
      //   selectPageModule: 1,
      //   selectStatus: "Y",
      // }}
      initialValues={{
        pageCode: objEdit ? objEdit.page_code : "",
        pageName: objEdit ? objEdit.page_name : "",
        pageIcon: objEdit ? objEdit.page_icon : "",
        pageSequence: objEdit ? objEdit.page_seq : "",
        selectPageModule: objEdit ? objEdit.module_id : "",
        selectStatus: objEdit ? objEdit.is_active : "Y",
        pagePath: objEdit ? objEdit.page_path : "",
      }}
      onSubmit={async (values) => {
        console.log("values:::", values);
        if (props.openPopupUpdateForm) {
          console.log("update");
          await onUpdatePage(values);
        } else {
          console.log("save");

          await onSavePage(values);
        }
      }}
      validationSchema={Yup.object().shape({
        pageCode: Yup.string().required("โปรดใส่ข้อมูล"),
        pageName: Yup.string().required("โปรดใส่ข้อมูล"),
        pageIcon: Yup.string().required("โปรดใส่ข้อมูล"),
        pageSequence: Yup.number().required("โปรดใส่ข้อมูล"),
        selectPageModule: Yup.number().required("โปรดใส่ข้อมูล"),
        selectStatus: Yup.string().required("โปรดใส่ข้อมูล"),
        pagePath: Yup.string().required("โปรดใส่ข้อมูล"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="managePage">
                <b>Management Page</b>
              </h5>
              <button
                id="btnClose"
                type="button"
                className="close"
                onClick={() => onClosePopup()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group col-12 row">
                <label
                  htmlFor="pageCode"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Page Code</b>
                </label>
                <input
                  type="text"
                  id="pageCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pageCode}
                  maxLength="6"
                  className={
                    errors.pageCode && touched.pageCode
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                  name="pageCode"
                  placeholder="CODE"
                />
                {errors.pageCode && touched.pageCode && (
                  <div className="input-feedback">{errors.pageCode}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="pageName"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Page Name</b>
                </label>
                <input
                  type="text"
                  id="pageName"
                  placeholder="NAME"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pageName}
                  className={
                    errors.pageName && touched.pageName
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                />
                {errors.pageName && touched.pageName && (
                  <div className="input-feedback">{errors.pageName}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="icon"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Page Icon</b>
                </label>
                <input
                  type="text"
                  id="pageIcon"
                  value={values.pageIcon}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="fa fa-circle-o"
                  className={
                    errors.pageName && touched.pageName
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                />
                {errors.pageIcon && touched.pageIcon && (
                  <div className="input-feedback">{errors.pageIcon}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="seq"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Page Sequence</b>
                </label>
                <input
                  id="pageSequence"
                  type="number"
                  placeholder="Sequence"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pageSequence}
                  className={
                    errors.pageSequence && touched.pageSequence
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                />
                {errors.pageSequence && touched.pageSequence && (
                  <div className="input-feedback">{errors.pageSequence}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="icon"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Page Path</b>
                </label>
                <input
                  type="text"
                  id="pagePath"
                  value={values.pagePath}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Page Path"
                  className={
                    errors.pageName && touched.pageName
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                />
                {errors.pagePath && touched.pagePath && (
                  <div className="input-feedback">{errors.pagePath}</div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="selectPageModule"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Page Module</b>
                </label>
                <select
                  id="selectPageModule"
                  value={values.selectPageModule}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.selectPageModule && touched.selectPageModule
                      ? "form-control col-7 text-input error"
                      : "form-control col-7 text-input"
                  }
                >
                   <option value="">กรุณาเลือก</option>
                  {moduleList && moduleList.map(((i,key) => {
                    return(
                      <option value={i.value}>{i.label}</option>
                    )
                  }))}
                </select>
                {errors.selectPageModule && touched.selectPageModule && (
                  <div className="input-feedback">
                    {errors.selectPageModule}
                  </div>
                )}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="selectStatus"
                  className="col-5 col-form-label d-flex justify-content-end"
                >
                  <b>Status</b>
                </label>
                <select
                  id="selectStatus"
                  value={values.selectStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.selectStatus && touched.selectStatus
                      ? "form-control col-7 text-input error"
                      : "form-control col-7 text-input"
                  }
                >
                  <option value={"Y"}>ใช้งาน</option>
                  <option value={"N"}>ไม่ใช้งาน</option>
                </select>
                {errors.selectStatus && touched.selectStatus && (
                  <div className="input-feedback">{errors.selectStatus}</div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // onClick={handleReset}
                onClick={() => onClosePopup()}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                บันทึก
              </button>
            </div>
            {/* </div>
              </div>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
}
