import axios from "axios";
const logger = require("./Logger");

export const getFetch = async (url, method, headers, body, timeout) => {
  logger.debug("API/fetch|url:" + url + "|method=" + method + "|headers=" + JSON.stringify + "|body=" + body);
  let retVal = null;

  try {
    
    await axios({
      method: method,
      url: url,
      data: body,
      headers: headers,
      timeout: timeout,
      withCredentials:true
    })
    .then((res) => res.data)
    .then(
      (data) => {
        if (data && data.RESULT_STATUS === "000") {
          retVal = data;
        } else if (data && data.RESULT_STATUS === "999") {
          retVal = data;
        }
      }
      ,(error) => {
        retVal = { error: true, message: error.message };
      }
      )

  } catch (err) {
    logger.error(err);

    // if (err.response) {
    //   logger.error(err.response.data);
    //   logger.error(err.response.status);
    //   logger.error(err.response.headers);

    // if (err.response.status === 401) {
    //     window.location.href = "/tol-selfservice-webapi/UnAuthorize"
    // }
    throw err;
  }

  return retVal;
};
